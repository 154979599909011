import createPersistedState from 'use-persisted-state'
import { DragDropContext, DropResult } from 'react-beautiful-dnd'
import { v4 as uuid } from 'uuid'
import produce from 'immer'

import { Column } from '../Column'
import styles from './index.module.css'

const sampleItems = [
  {
    id: uuid(),
    heading: 'Technical Call 2',
    avatar: 'https://picsum.photos/seed/1/24/24',
    content:
      'Have a call with Close’s Engineering Manager and Frontend Tech Lead.',
  },
  {
    id: uuid(),
    heading: 'Cultural Call',
    avatar: 'https://picsum.photos/seed/2/24/24',
    content: 'Get to know Mary Hartberg, Close’s queen of culture!',
  },
  {
    id: uuid(),
    heading: 'General Call',
    avatar: 'https://picsum.photos/seed/3/24/24',
    content:
      'Time to talk to Phil Freo, the Director of Engineering at Close :D',
  },
]

export const COLUMNS = [
  {
    id: 'TO_DO',
    name: 'To do',
    items: sampleItems,
  },
  {
    id: 'IN_PROGRESS',
    name: 'In progress',
    items: [],
  },
  {
    id: 'DONE',
    name: 'Done',
    items: [],
  },
]

const useCardsState = createPersistedState('cards')

export const Board = () => {
  const [columns, setColumns] = useCardsState(COLUMNS)

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return
    }

    const { source, destination } = result

    if (source.droppableId !== destination.droppableId) {
      setColumns(
        produce((columns) => {
          const sourceColumn = columns.find(
            (column) => column.id === source.droppableId,
          )
          const destColumn = columns.find(
            (column) => column.id === destination.droppableId,
          )

          const [removed] = sourceColumn.items.splice(source.index, 1)
          destColumn.items.splice(destination.index, 0, removed)
        }),
      )
    } else {
      setColumns(
        produce((columns) => {
          const column = columns.find(
            (column) => column.id === source.droppableId,
          )
          const [removed] = column.items.splice(source.index, 1)
          column.items.splice(destination.index, 0, removed)
        }),
      )
    }
  }

  return (
    <div className={styles.board}>
      <DragDropContext onDragEnd={(result) => onDragEnd(result)}>
        {columns.map((column) => (
          <Column column={column} key={column.id}></Column>
        ))}
      </DragDropContext>
    </div>
  )
}

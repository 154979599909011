import React from 'react'
import clsx from 'clsx'
import { Draggable } from 'react-beautiful-dnd'
import { Columns } from '../types'

import styles from './index.module.css'

type props = {
  item: Columns[number]['items'][number]
  idx: number
}

const Card: React.FC<{ item: props['item'] }> = React.memo(({ item }) => {
  return (
    <>
      <div className={styles.heading}>
        <span>
          <img className={styles.avatar} src={item.avatar} alt="avatar"></img>
        </span>
        {item.heading}
      </div>
      <hr className={styles.separation} />
      <span className={styles.content}>{item.content}</span>
    </>
  )
})

export const DraggableCard: React.FC<props> = ({ item, idx }) => {
  return (
    <Draggable draggableId={item.id} index={idx}>
      {(provided, snapshot) => {
        return (
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            className={clsx(
              styles.container,
              snapshot.isDragging && styles.isDragging,
            )}
            style={{
              ...provided.draggableProps.style,
            }}
          >
            <Card item={item}></Card>
          </div>
        )
      }}
    </Draggable>
  )
}

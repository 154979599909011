import { Droppable } from 'react-beautiful-dnd'
import clsx from 'clsx'

import type { Columns } from '../types'
import styles from './index.module.css'
import { DraggableCard } from '../Card'

export const Column: React.FC<{ column: Columns[number] }> = ({ column }) => {
  return (
    <div className={styles.main}>
      <div className={styles.heading}>{column.name}</div>

      <Droppable droppableId={column.id} key={column.id}>
        {(provided, snapshot) => {
          return (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              className={clsx(
                styles.container,
                snapshot.isDraggingOver && styles.isDragging,
              )}
            >
              {column.items.map((item, idx) => {
                return (
                  <DraggableCard
                    item={item}
                    key={item.id}
                    idx={idx}
                  ></DraggableCard>
                )
              })}
              {provided.placeholder}
            </div>
          )
        }}
      </Droppable>
    </div>
  )
}

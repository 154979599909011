import Header from './layout/Header'
import { Board } from './screens/Board'

function App() {
  return (
    <>
      <Header />
      <Board />
    </>
  )
}

export default App
